<template>
  <div class="page page__content-wrapper">
    <div class="page__min-container">
      <div class="text__head">
        Обработка
      </div>
      
      <div class="text__normal">
        Фото и видео подбираются (или, в случае необходимости, обрабатываются) таким образом, чтобы выглядеть максимально реалистично и «не карамельно» — это стальные сухогрузы, пасмурные моря, круглосуточные центры управления, спутниковая аэрофотосъемка.
      </div>

      <div class="image__item">
        <img class="image" src="~assets/img/photo-page/photo-1.jpg" alt="">
      </div>

      <div class="text__list">
        <div class="text__normal">
          Основное правило — уменьшать яркость визуалов настолько, чтобы поверх можно было легко разместить HUD-элементы без ущерба читаемости. Это правило стоит соблюдать в качестве ориентира даже тогда, когда визуалы планируется использовать отдельно — без HUD‑элементов поверх.
        </div>
        <div class="text__normal">
          Насыщенность визуалов должна быть ниже среднего.
        </div>
      </div>

      <div class="image__item">
        <img class="image" src="~assets/img/photo-page/photo-2.jpg" alt="">
      </div>

      <div class="text__list">
        <div class="text__normal">
          Локальный контраст на визуалах не должен быть выше среднего.
        </div>
        <div class="text__normal">
          В меру зернистые («шумные») визуалы хорошо вписываются в стиль, и если фотография или видео выглядит слишком гладким и чистым, стоит добавить сверху дополнительное зерно. Это не только не испортит картинку, но и послужит хорошим контрастом к другим — чистым, векторным — элементам фирменного стиля.
        </div>
        <div class="text__normal">
          При подборе визуалов стоит отдавать предпочтение «фоновым» фотографиям и&nbsp;видео&nbsp;— то есть визуалам, в которых нет больших перепадов по яркости. К примеру,  ярко-белое судно в иссиня-черном океане не подойдет.
        </div>
      </div>

      <div class="image__wrapper">
        <div class="image__row">
          <div class="image__item">
            <img class="image" src="~assets/img/photo-page/photo-3.jpg" alt="">
          </div>
        </div>
        <div class="image__row">
          <div class="image__item">
            <img class="image" src="~assets/img/photo-page/photo-4.jpg" alt="">
          </div>
          <div class="image__item">
            <img class="image" src="~assets/img/photo-page/photo-5.jpg" alt="">
          </div>
        </div>
        <div class="image__row">
          <div class="image__item">
            <img class="image" src="~assets/img/photo-page/photo-6.jpg" alt="">
          </div>
        </div>
      </div>
    </div>

    <bottom-nav-block :prevLink="bottomNav.pre" :nextLink="bottomNav.next"/>
  </div>
</template>

<script>
import BottomNavBlock from "@/components/Elements/BottomNavBlock/BottomNavBlock"
export default {
  components: {BottomNavBlock},
  data() {
    return {
      bottomNav: {
        pre: {
          url: "/identity/picto",
          text: "Пиктограммы"
        },
        next: {
          url: "/identity/communication-rules",
          text: "Правила коммуникаций"
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/style/page-block";
@import "src/assets/style/text-style";
@import "src/assets/style/var-style";

.page {
  margin-bottom: 144px;
}

.text {
  &__head {
    font-size: 26px;
    line-height: 40px;
    margin-bottom: 32px;
    letter-spacing: 0;
  }
  &__normal {
    max-width: 688px;
    letter-spacing: 0;
    font-weight: 400;
    margin-bottom: 48px;
  }
  &__list {
    margin-bottom: 48px;
    .text__normal {
      margin-bottom: 24px;
    }
  }
}

.image {
  width: 100%;
  border-radius: 4px;
  &__row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    &:first-child {
      .image__item {
        margin-bottom: 32px;
      }
    }
    &:nth-child(2) {
      .image__item {
        width: calc(50% - 16px);
        margin-bottom: 32px;
      }
    }
  }
  &__item {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 48px;
  }
}


@media (max-width: $mobile) {
  .page {
    padding-top: 48px;
    margin-bottom: 143px;
  }
  .text {
    &__list {
      margin-bottom: 47px;
    }

    &__head {
      margin-bottom: 24px;
    }
  }

  .image {
    border-radius: 4px;
    &__row {
      &:first-child {
        .image__item {
          margin-bottom: 31px;
        }
      }
      &:nth-child(2) {
        .image__item {
          width: calc(50% - 7px);
          margin-bottom: 32px;
        }
      }
    }
  }
}
</style>